<template lang="pug">
.dropdown
  .dropbtn {{ user.firstName }} {{ user.lastName }}
    font-awesome-icon.space-l-sm(icon='circle-user' size="2xl")
  .dropdown-content
    a(@click="myProfile()") My Profile
    a(@click="changePassword()") Change Password
    a(@click="logout()") Logout
</template>

<script>
import LoginService from '@/services/LoginService';
import apiProcessor from '@/services/ApiProcessor';

export default {
  name: 'UserMenu',
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    myProfile() {
      this.$router.push('/user');
    },
    changePassword() {
      this.$router.push('/changePassword');
    },
    logout() {
      LoginService.logout()
        .then(() => {
          localStorage.setItem('token', null);
          apiProcessor.removeHeader('Authorization');
          this.$store.dispatch('logout');
          this.$router.push('/login');
        });
    },
  },
};

</script>

<style scoped>
  .dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {background-color: #ddd;}

  .dropdown:hover .dropdown-content {display: block;}

  .dropdown:hover .dropbtn

</style>
